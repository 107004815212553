import Vue from 'vue'
import Vuex from 'vuex'

import account from './modules/account'
import recons from './modules/recons'
import diferencas from './modules/diferencas'
import searches from './modules/searches'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    account: account,
    recons: recons,
    diferencas: diferencas,
    searches: searches,
  }
})
