import axios from 'axios'

const state = {
  recons: [],
  reconsPrev: null,
  reconsNext: null,
  reconsPg: null,
  reconsTotReg: null,
  reconsTotPag: null,
}

const getters = {
  getreconById: (state) => (id) => {
    return state.recons.find(op => op.id === id)
  },
  
}

const mutations = {
  clearrecons (state) {
    state.recons = []
    state.reconsPrev = null
    state.reconsNext = null
    state.reconsPg = null
    state.reconsTotReg = null
    state.reconsTotPag = null
  },

  setrecons (state, { results, prev, next, pg, tot, totp }) {
    state.recons = results
    state.reconsPrev = prev
    state.reconsNext = next
    state.reconsPg = pg
    state.reconsTotReg = tot
    state.reconsTotPag = totp
  },

  addrecon (state, result) {
    let idx = state.recons.findIndex(op => op.id === result.id)
    if (idx !== -1) {
      state.recons.splice(idx, 1, result)
    } else {
      state.recons.push(result)
    }
  },
}

const actions = {
  async getrecons ({ commit, rootGetters }, url) {
    if (!url) {
      url = process.env.VUE_APP_API_URL + `/api/recons/`
    }

    let response = await axios
      .get(url,
        { headers: rootGetters['account/getAuthHeader'], },
      )

      await commit('setrecons',
        { 'results': response.data.results,
          'prev': response.data.previous,
          'next': response.data.next,
          'pg': response.data.page,
          'tot': response.data.count,
          'totp': response.data.total_pages,
        })
  },

  async getResource ({ rootGetters }, url) {
    let response = await axios
      .get(url,
        { headers: rootGetters['account/getAuthHeader'], }
      )

    return response.data
  },

  async getreconsid ({ dispatch, commit }, id) {
    let url = process.env.VUE_APP_API_URL + `/api/recons/${id}/`
    let response = await dispatch('getResource', url)
    await commit('addrecon', response)
    return response
  },
  
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
