<template>
    <div>
      <v-container>
        <v-form lazy-validation he>
              <v-row> Selecione o arquivo zipado que a Caixa envia:</v-row>
              <v-row>
                 <v-file-input accept=".zip" v-on:change="onFileChange"></v-file-input>
              </v-row>
              <v-row> Se desejar, inclua uma descrição para a Recon:</v-row>
              <v-row>
                <v-text-field label="Descrição da Recon" v-model="desc"></v-text-field>
              </v-row>
              <v-row>
                <v-btn :loading="loading" :disabled="loading" color="primary" class="ma-2 white--text" @click="criarRecon">
                  Criar Recon
                <v-icon right dark>
                  mdi-cloud-upload
                </v-icon>
                </v-btn>
              </v-row>
              <v-row v-model="status"> {{status}} </v-row>
        </v-form>
      </v-container>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    setup() {
    },
    data () {
      return {
        loading: false,
        arquivo: '',
        desc: '',
        status: '',
      }
    },
    watch: {
    },
    methods: {
      onFileChange(e) {     
        this.arquivo = e;
      },
      criarRecon: 
        async function () {
          this.loading = true;
          let formData = new FormData();
          formData.append('arquivo', this.arquivo);
          formData.append('descricao', this.desc+' ('+localStorage.getItem('username')+')');

          let hdrs = this.$store.getters['account/getAuthHeader']
          hdrs['Content-Type'] = 'multipart/form-data'
          try{  
            let response = await axios
              .post(process.env.VUE_APP_API_URL + '/api/recons/',
                formData,
                { headers: hdrs, }
              )
            // this.status = 'Carregando arquivo, aguarde um instante...'
            // await axios
            //       .get(response.data.url+'carregar_arquivo_caixa/', 
            //       { headers: this.$store.getters['account/getAuthHeader'], },)
            // this.status = 'Carregando dados do Flow... essa operação é demorada, vá tomar um café...'
            // await axios
            //       .get(response.data.url+'buscar_dados_flow/', 
            //       { headers: this.$store.getters['account/getAuthHeader'], },)
            // this.status = 'Identificando chaves que existem no arquivo da Caixa, mas não no Flow...'
            // await axios
            //       .get(response.data.url+'gerar_caixa_sem_flow/', 
            //       { headers: this.$store.getters['account/getAuthHeader'], },)
            // this.status = 'Identificando chaves que existem no Flow, mas não no arquivo da Caixa...'
            // await axios
            //       .get(response.data.url+'gerar_flow_sem_caixa/', 
            //       { headers: this.$store.getters['account/getAuthHeader'], },)
            this.status = 'Tasks de carga de arquivo Caixa, download de dados do Flow, geração de divergências de chave estão sendo processadas...'  
            await axios
              .get(response.data.url+'processar_full/', 
              { headers: this.$store.getters['account/getAuthHeader'], },)
            this.loading = false;
            this.$router.push('/'); 
          }catch{
              this.loading = false;
              this.status = "Erro!"
            }
        },
    },
}
</script>