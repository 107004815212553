<template>
  <div>
      <Recons v-if="$store.getters['account/loggedin']" />
      <v-container v-else fluid grid-list-md class="my-16">
        <v-row align="center" justify="center" class="my-2">Usuário não logado</v-row>
        <v-row align="center" justify="center"><AppLoginDialog v-bind:dialogHome="true"></AppLoginDialog></v-row>
      </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Recons from '../components/Recons.vue'
import AppLoginDialog from '../components/AppLoginDialog.vue'
export default {
  components: {
    Recons, AppLoginDialog
  },

  computed: mapState([
    'account'
  ]),
};
</script>
