<template>
  <div>
    <v-card >
      <v-card-title class="justify-center">Chaves divergentes da Recon {{this.response.id}}</v-card-title>
      <v-card-text>
        <v-row justify="start">Recon ID: {{this.response.id}} </v-row>
        <v-row justify="start">Data: {{formattedDate(this.response.created_at)}} </v-row>
        <v-row justify="start">Descrição: {{this.response.descricao}}</v-row>
        <v-row justify="start">Total de chaves divergentes: {{diferencas.diferencasTotReg}}</v-row>
      </v-card-text>
    </v-card>
    <v-data-table
      :headers="headers"
      :items="diferencas.diferencas"
      item-key="id"
      class="elevation-1"
      disable-pagination
      hide-default-footer
      fixed-header
    >
        <template v-slot:item="{ item }">
            <tr >
                <td >{{item.flow ? item.flow.cpf : item.caixa.CPF}}</td>
                <td>{{item.flow ? item.flow.id_solicitacao : item.caixa.id_solicitacao}}</td>
                <td>{{item.flow ? item.flow.valor_parcela : item.caixa.valor_original}}</td>
                <td v-if="item.falta_flow"><v-icon color='red'>mdi-minus-circle-outline</v-icon></td>
                <td v-else><v-icon color='green'>mdi-check-circle-outline</v-icon></td>
                <td v-if="item.falta_caixa"><v-icon color='red'>mdi-minus-circle-outline</v-icon></td>
                <td v-else><v-icon color='green'>mdi-check-circle-outline</v-icon></td>
                <td>{{item.caixa ? item.caixa.status_periodo : '-'}}</td>
                <td>{{item.flow ? item.flow.status_contrato : '-'}}</td>
                <td>{{item.caixa ? item.caixa.data_pagamento : '-'}}</td>
            </tr>
        </template>
    </v-data-table>
      <v-row align="center" justify="center" class="my-4">
      <v-btn color = "primary"
        :disabled="diferencas.diferencasPrev === null"
        @click="fetchData(diferencas.diferencasPrev)"
      >
        <v-icon>mdi-chevron-left</v-icon> 
      </v-btn>
      <td style="color: grey;" class="mx-4">Página: {{diferencas.diferencasPg}}/{{diferencas.diferencasTotPag}}</td>
      <v-btn color = "primary" 
        :disabled="diferencas.diferencasNext === null"
        @click="fetchData(diferencas.diferencasNext)"
      >
         <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      </v-row>
  </div>
</template>


<script>
import { mapState } from 'vuex'
import dateFmt from '../mixins/dateFmt'
  export default {
    data () {
      return {
        headers: [
            { text: 'CPF',  value: 'CPF', align: 'start'},
            { text: 'Chave', value: 'idSolicitacao' },
            { text: 'Valor', value: 'valorOriginal' },
            { text: 'Tem no Flow', value: 'faltaCaixa' },
            { text: 'Tem na Caixa', value: 'faltaFlow' },
            { text: 'Status Contrato Caixa', value: '' },
            { text: 'Status Contrato Flow', value: '' },
            { text: 'Data pagamento Caixa', value: '' },
        ],
        items:[],
        item:'',
        response: '',
      }
    },
    components: {
    },
    mixins: [dateFmt],
    props: {
      reconId: Number,
    },
    created () {
        this.fetchDataId()
    },
    computed: {
        ...mapState(['diferencas','recons']),
    },
    methods: {
    async fetchData (url) {
      await this.$store.dispatch('diferencas/getdiferencas',url)
    },
    async fetchDataId () {
      await this.$store.dispatch('diferencas/getDiferencasFromReconId',this.reconId)
      this.response =  await this.$store.dispatch('recons/getreconsid',this.reconId)
      // console.log(this.$store.state.recons.recons.find(op => op.id === this.reconId))
      //console.log(this.$store.getters['recons/getreconById'](this.reconId))
    },
    fechar() {
      this.$router.push('/'); 
    },
  },
  }
</script>