import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Upload from '../components/Upload.vue'
import Diferencas from '../components/Diferencas.vue'
import AppLoginDialog from '../components/AppLoginDialog.vue'
import Search from '../components/Search.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/upload',
    name: 'Upload',
    component: Upload
  },
  {
    path: '/diferencas/:reconId',
    name: 'Diferencas',
    component: Diferencas,
    props: true
  },
  {
    path: '/login',
    name: 'AppLoginDialog',
    component: AppLoginDialog
  },
  {
    path: '/search',
    name: 'Search',
    component: Search
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
