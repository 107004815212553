<template>
<!-- App.vue -->

<v-app >
 
  <v-app-bar app>
    <!-- <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon> -->
    <div>
      <v-btn @click="$router.push('/').catch(()=>{})" color="primary" class="mx-2" >
        <v-icon>
          mdi-home
        </v-icon>
      </v-btn>
      <v-btn @click="$router.push('/search').catch(()=>{})" color="primary" class="mx-2" >
        <v-icon>
          mdi-magnify
        </v-icon>
      </v-btn>
    </div>
    <v-spacer></v-spacer>
    <h3>Recon FGTS</h3>
    <v-spacer></v-spacer>
    <template v-if="$store.getters['account/loggedin']">
      <AppAccountMenu />
    </template>
    <template v-else>
      <AppLoginDialog></AppLoginDialog>
    </template>
  </v-app-bar>
  <!-- Sizes your content based upon application components -->
  <v-main>
    <!-- Provides the application the proper gutter -->
    <v-container fluid>
      <!-- If using vue-router -->
      <router-view></router-view>
    </v-container>
  </v-main>
  <v-footer app>
    <!-- -->
  </v-footer>
</v-app>
</template>

<script>
import { mapState } from 'vuex'
import AppLoginDialog from './components/AppLoginDialog'
import AppAccountMenu from './components/AppAccountMenu'
export default {
  name: 'App',
  components: {
    AppLoginDialog,
    AppAccountMenu,
  },
  data: () => ({
    items: [
      { title: 'Recons', icon: 'mdi-view-dashboard', router: "/" },
      // { title: 'Upload do arquivo da caixa (.zip)', icon: 'mdi-view-dashboard', router: "upload"},
      // { title: 'Carregar dados do Flow', icon: 'mdi-view-dashboard', router: "flow" },
      // { title: 'Chaves que têm na Caixa e não tem no Flow', icon: 'mdi-view-dashboard', router: "difcaixa" },
      // { title: 'Chaves que têm no Flow e não tem na Caixa', icon: 'mdi-view-dashboard', router: "difflow" },
    ],
    drawer: false,
    group: null,
    listaRecons: [
      {id:'', data_upload:'', desc:''},
    ],
  }),
  watch: {
      group () {
        this.drawer = false
      },
  },
  computed: {
    ...mapState(['account',]),
  },
};
</script>