import axios from 'axios'

const state = {
  diferencas: [],
  diferencasPrev: null,
  diferencasNext: null,
  diferencasPg: null,
  diferencasTotReg: null,
  diferencasTotPag: null,
}

const getters = {
  getdiferencaById: (state) => (id) => {
    return state.diferencas.find(op => op.id === id)
  },
}

const mutations = {
  cleardiferencas (state) {
    state.diferencas = []
    state.diferencasPrev = null
    state.diferencasNext = null
    state.diferencasPg = null
    state.diferencasTotReg = null
    state.diferencasTotPag = null
  },

  setdiferencas (state, { results, prev, next, pg, tot, totp }) {
    state.diferencas = results
    state.diferencasPrev = prev
    state.diferencasNext = next
    state.diferencasPg = pg
    state.diferencasTotReg = tot
    state.diferencasTotPag = totp
  },

  adddiferenca (state, result) {
    let idx = state.diferencas.findIndex(op => op.id === result.id)
    if (idx !== -1) {
      state.diferencas.splice(idx, 1, result)
    } else {
      state.diferencas.push(result)
    }
  },
}

const actions = {
  async getdiferencas ({ commit, rootGetters }, url) {
    if (!url) {
      url = process.env.VUE_APP_API_URL + `/api/diferencas`
    }
    let response = await axios
      .get(url,
        { headers: rootGetters['account/getAuthHeader'], },
      )

      await commit('setdiferencas',
        { 'results': response.data.results,
          'prev': response.data.previous,
          'next': response.data.next,
          'pg': response.data.page,
          'tot': response.data.count,
          'totp': response.data.total_pages,
        })
  },

  async getDiferencasFromReconId ({ commit, rootGetters }, id) {
    let url = process.env.VUE_APP_API_URL + `/api/diferencas/?recon_id=${id}`
    
    let response = await axios
      .get(url,
        { headers: rootGetters['account/getAuthHeader'], },
      )

      await commit('setdiferencas',
        { 'results': response.data.results,
          'prev': response.data.previous,
          'next': response.data.next,
          'pg': response.data.page,
          'tot': response.data.count,
          'totp': response.data.total_pages,
        })
  },


  async getResource ({ rootGetters }, url) {
    let response = await axios
      .get(url,
        { headers: rootGetters['account/getAuthHeader'], }
      )

    return response.data
  },

  async getdiferencasid ({ dispatch, commit }, id) {
    let url = process.env.VUE_APP_API_URL + `/api/diferencas/?recon_id=${id}`
    let response = await dispatch('getResource', url)
    await commit('adddiferenca', response)
    return response
  },
  
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
