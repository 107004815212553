<template>
  <div>
    <v-data-table 
      :headers="headers"
      :items="recons.recons"
      item-key="id"
      class="elevation-1"
      disable-pagination
      hide-default-footer
      fixed-header
    >
        <template v-slot:item="{ item }">
            <tr>
                <td>{{formattedDate(item.created_at)}}</td>
                <td>{{item.id}}</td>
                <td>{{item.descricao}}</td>
                <td v-if="item.subiu_caixa"><v-icon color='green'>mdi-check-circle-outline</v-icon></td>
                <td v-else><v-icon color='red'>mdi-minus-circle-outline</v-icon></td>
                <td v-if="item.subiu_flow"><v-icon color='green'>mdi-check-circle-outline</v-icon></td>
                <td v-else><v-icon color='red'>mdi-minus-circle-outline</v-icon>
                <v-btn icon @click="recargaFlow(item)" :loading="loading" :disabled="loading"> 
                <v-icon>mdi-cloud-download-outline</v-icon></v-btn>
                {{item.page_flow}}/{{item.total_pages_flow}}
                </td>
                <td v-if="item.gerou_caixa_sem_flow && item.gerou_flow_sem_caixa">
                  <v-btn class="mx-2" fab dark x-small color="primary" :to="{ name: 'Diferencas', params: { reconId: item.id }  }">
                    <v-icon dark>mdi-magnify-plus-outline</v-icon>
                  </v-btn>
                  <v-btn class="mx-2" fab dark x-small color="green" @click="downloadCSV(item)">
                    <v-icon dark>mdi-microsoft-excel</v-icon>
                  </v-btn>
                </td>
                <td v-else>-</td>
                <td>{{item.total_pago_mes_caixa}}</td>
            </tr>
        </template>
    </v-data-table>
    <div>
      <v-row align="center" justify="center" class="my-4">
      <v-btn color="primary"
        :disabled="recons.reconsPrev === null"
        @click="fetchData(recons.reconsPrev)"
      >
        <v-icon>mdi-chevron-left</v-icon> 
      </v-btn>
      <td class="mx-4" style="color: grey;">Página: {{recons.reconsPg}}/{{recons.reconsTotPag}}</td>
      <v-btn color="primary"
        :disabled="recons.reconsNext === null"
        @click="fetchData(recons.reconsNext)"
      >
         <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      </v-row>
      <v-row align="center" justify="space-around" >
        <v-btn @click="$router.push('upload').catch(()=>{})" color="primary" fab small class="my-8">
          <v-icon>
            mdi-plus
          </v-icon>
        </v-btn>
      </v-row>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import axios from 'axios'
import dateFmt from '../mixins/dateFmt'
export default {
  name: 'Home',
  components: {
  },
  data: () => ({
    headers: [
        { text: 'Data da criação',  value: 'data_upload', align: 'start'},
        { text: 'ID da Recon',  value: 'id'},
        { text: 'Descrição', value: 'desc' },
        { text: 'Carregou arquivo Caixa?', value: 'cx' },
        { text: 'Puxou dados do Flow?', value: 'fl' },
        { text: 'Diferenças de chave entre Caixa e Flow', value: 'cxfl' },
        { text: 'Total pago pela Caixa (ref. Data de corte)', value: 'tpmc' },
    ],
    items:[],
    item:'',
    loading: false,
  }),
  created () {
    this.fetchData()
  },
  computed: {
    ...mapState(['recons',]),
  },
  mixins: [dateFmt],
  methods: {
    async fetchData (url) {
      await this.$store.dispatch('recons/getrecons', url)
    },
    recargaFlow:
    async function (item) {
      this.loading = true;
      try{
        await axios
          .get(item.url+'buscar_dados_flow/', 
          { headers: this.$store.getters['account/getAuthHeader'], },)
        await axios
              .get(item.data.url+'gerar_caixa_sem_flow/', 
              { headers: this.$store.getters['account/getAuthHeader'], },)
        await axios
              .get(item.data.url+'gerar_flow_sem_caixa/', 
              { headers: this.$store.getters['account/getAuthHeader'], },)
        this.loading = false;
      }catch{
        this.loading = false;
      } 
    },
    downloadCSV(item){
      axios({
          url: item.url+'download_csv/', 
          method: 'GET',
          responseType: 'blob',
          headers: this.$store.getters['account/getAuthHeader'],
      }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'resultado'+item.id+'.csv');
          document.body.appendChild(link);
          link.click();
          console.log(url);
      });
    }
  },
}
</script>
