import axios from 'axios'

const state = {
  searches: [],
  searchesPrev: null,
  searchesNext: null,
  searchesPg: null,
  searchesTotReg: null,
  searchesTotPag: null,
}

const getters = {
  getsearchById: (state) => (id) => {
    return state.searches.find(op => op.id === id)
  },
}

const mutations = {
  clearsearches (state) {
    state.searches = []
    state.searchesPrev = null
    state.searchesNext = null
    state.searchesPg = null
    state.searchesTotReg = null
    state.searchesTotPag = null
  },

  setsearches (state, { results, prev, next, pg, tot, totp }) {
    state.searches = results
    state.searchesPrev = prev
    state.searchesNext = next
    state.searchesPg = pg
    state.searchesTotReg = tot
    state.searchesTotPag = totp
  },

  addsearch (state, result) {
    let idx = state.searches.findIndex(op => op.id === result.id)
    if (idx !== -1) {
      state.searches.splice(idx, 1, result)
    } else {
      state.searches.push(result)
    }
  },
}

const actions = {
  async getsearches ({ commit, rootGetters }, url) {
    if (!url) {
      url = process.env.VUE_APP_API_URL + `/api/dadosCaixaPorCPF`
    }
    let response = await axios
      .get(url,
        { headers: rootGetters['account/getAuthHeader'], },
      )

      await commit('setsearches',
        { 'results': response.data.results,
          'prev': response.data.previous,
          'next': response.data.next,
          'pg': response.data.page,
          'tot': response.data.count,
          'totp': response.data.total_pages,
        })
  },

  async getsearchesFromReconId ({ commit, rootGetters }, {id, cpf}) {
    let url = process.env.VUE_APP_API_URL + `/api/dadosCaixaPorCPF/?recon_id=${id}&cpf=${cpf}`
    
    let response = await axios
      .get(url,
        { headers: rootGetters['account/getAuthHeader'], },
      )

      await commit('setsearches',
        { 'results': response.data.results,
          'prev': response.data.previous,
          'next': response.data.next,
          'pg': response.data.page,
          'tot': response.data.count,
          'totp': response.data.total_pages,
        })
  },


  async getResource ({ rootGetters }, url) {
    let response = await axios
      .get(url,
        { headers: rootGetters['account/getAuthHeader'], }
      )

    return response.data
  },

  async getsearchesid ({ dispatch, commit }, {id, cpf} ) {
    let url = process.env.VUE_APP_API_URL + `/api/dadosCaixaPorCPF/?recon_id=${id}&cpf=${cpf}`
    let response = await dispatch('getResource', url)
    await commit('addsearch', response)
    return response
  },
  
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}
