<template>
  <div>
    <v-container>
      <v-form lazy-validation he>
            <v-row> Digite o ID da Recon:</v-row>
            <v-row>
               <v-text-field label="Id da Recon" v-model="reconId"></v-text-field>
            </v-row>
            <v-row> Digite o CPF desejado:</v-row>
            <v-row>
              <v-text-field label="CPF a ser buscado" v-model="cpf"></v-text-field>
            </v-row>
            <v-row>
              <v-btn :loading="loading" :disabled="loading" color="primary" class="ma-2 white--text" @click="buscarDadosCPF">
                Buscar Dados do CPF no arquivo CEF
              <v-icon right dark>
                mdi-magnify
              </v-icon>
              </v-btn>
            </v-row>
      </v-form>
    </v-container>
    <v-data-table
      :headers="headers"
      :items="searches.searches"
      item-key="id"
      class="elevation-1"
      disable-pagination
      hide-default-footer
      fixed-header
    >
      <template v-slot:item="{ item }">
          <tr >
              <td >{{item.CPF}}</td>
              <td >{{item.pedido_em}}</td>
              <td >{{item.id_solicitacao}}</td>
              <td >{{item.tipo_operacao}}</td>
              <td >{{item.canal_solicitacao}}</td>
              <td >{{item.status_periodo}}</td>
              <td >{{item.data_repasse}}</td>
              <td >{{item.valor_original}}</td>
              <td >{{item.valor_atualizado}}</td>
              <td >{{item.nu_protocolo}}</td>
              <td >{{item.status_protocolo}}</td>
              <td >{{item.data_pagamento}}</td>
              <td>{{reconId}}</td>
          </tr>
      </template>
    </v-data-table>
    <v-row align="center" justify="center" class="my-4">
      <v-btn color = "primary"
        :disabled="searches.searchesPrev === null"
        @click="fetchData(searches.searchesPrev)"
      >
        <v-icon>mdi-chevron-left</v-icon> 
      </v-btn>
      <td style="color: grey;" class="mx-4">Página: {{searches.searchesPg}}/{{searches.searchesTotPag}}</td>
      <v-btn color = "primary" 
        :disabled="searches.searchesNext === null"
        @click="fetchData(searches.searchesNext)"
      >
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      </v-row>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  setup() {
  },
  data () {
    return {
      headers: [
      { text: 'CPF',  value: 'CPF', align: 'start'},
      { text: 'Pedido em', value: 'pedido_em' },
      { text: 'id_solicitacao', value: 'id_solicitacao' },
      { text: 'tipo_operacao', value: 'tipo_operacao' },
      { text: 'canal_solicitacao', value: 'canal_solicitacao' },
      { text: 'status_periodo', value: 'status_periodo' },
      { text: 'data_repasse', value: 'data_repasse' },
      { text: 'valor_original', value: 'valor_original' },
      { text: 'valor_atualizado', value: 'valor_atualizado' },
      { text: 'nu_protocolo', value: 'nu_protocolo' },
      { text: 'status_protocolo', value: 'status_protocolo' },
      { text: 'data_pagamento', value: 'data_pagamento' },
      { text: 'recon', value: 'recon' },
        ],
      loading: false,
      reconId: '',
      cpf: '',
      response: false,
    }
  },
  computed: {
        ...mapState(['searches']),
    },
  methods: {
    buscarDadosCPF: 
      async function () {
        this.loading = true;
        try{ 
          this.response = await this.$store.dispatch('searches/getsearchesFromReconId', {'id':this.reconId, 'cpf': this.cpf}) 
          this.loading = false;
        }catch{
            this.loading = false;
          }
      },
  },
}
</script>